import React from 'react';
import styled from '@emotion/styled';
import { color, fontSize, fontWeight, spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';

const StyledAttributes = styled.div`
	display: flex;
	flex-direction: column;

	${breakpointUp('md')} {
		flex-direction: row;
	}
`;

const StyledAttribute = styled.div`
	margin-bottom: ${spacer['1']};

	&:not(:last-child) {
		margin-right: ${spacer['2']};

		&::after {
			content: '/';
			display: none;
			margin-left: ${spacer['2']};
			font-size: ${fontSize.md};
			font-weight: ${fontWeight.normal};

			${breakpointUp('md')} {
				display: inline-block;
				font-size: ${fontSize.lg};
			}
		}
	}
`;

const StyledName = styled.span`
	font-size: ${fontSize.xs};
	color: ${color.textMuted};
	font-weight: ${fontWeight.bold};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}
`;

const StyledValue = styled.strong`
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.bold};

	${breakpointUp('md')} {
		font-size: ${fontSize.lg};
	}
`;

type Attribute = {
	attribute: string;
	value: string;
};

type Props = {
	attributes: Attribute[];
};

const Attributes: React.FC<Props> = ({ attributes }) => {
	return (
		<StyledAttributes>
			{attributes.map(attribute => {
				if (attribute.value) {
					return (
						<StyledAttribute key={attribute.attribute}>
							<StyledValue>{attribute.value}</StyledValue>&nbsp;
							<StyledName>{attribute.attribute}</StyledName>
						</StyledAttribute>
					);
				}
			})}
		</StyledAttributes>
	);
};

export default Attributes;
