import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { breakpoints, fontSize, gutter, spacer } from '../styles/variables';
import styled from '@emotion/styled';
import { breakpointUp } from '../styles/mq';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-bottom: ${spacer['7']};

		${breakpointUp('md')} {
			margin-bottom: ${spacer['8']};
		}
	}

	${breakpointUp('md')} {
		flex-direction: row;
	}
`;

const StyledImgContainer = styled.div`
	${breakpointUp('md')} {
		flex-basis: 75%;
		padding-right: ${spacer['5']};
	}
`;

const StyledInfo = styled.div`
	${breakpointUp('md')} {
		flex-basis: 25%;
		padding-left: ${spacer['5']};
	}
`;

const StyledAuthor = styled.span`
	font-size: ${fontSize.xs};

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}
`;

type Props = {
	author?: string;
	image: IGatsbyImageData;
	alt?: string;
	loading?: 'eager' | 'lazy';
};

const ProjectImage: React.FC<Props> = ({ author, image, loading, alt }) => {
	return (
		<StyledContainer>
			<StyledImgContainer>
				<GatsbyImage
					image={image}
					sizes={`(max-width: ${breakpoints.sm - 1}px) calc(100vw - (${gutter.full} * 2)), (max-width: ${breakpoints.xxxl - 1}px) calc((100vw - (${gutter.md.full} * 2)) * .75), calc((99rem - (${gutter.md.full} * 2)) * .75)`}
					loading={loading}
					alt={alt || ''}
				/>
			</StyledImgContainer>
			<StyledInfo>{author && <StyledAuthor>© {author}</StyledAuthor>}</StyledInfo>
		</StyledContainer>
	);
};

export default ProjectImage;
