import React from 'react';
import { PageProps } from '../typing/Page';
import Layout from '../components/Layout';
import styled from '@emotion/styled';
import { ProjectDetail } from '../typing/Project';
import PageTitle from '../components/PageTitle';
import { replaceCategoryName } from '../utils/strings';
import Attributes from '../components/Attributes';
import { graphql } from 'gatsby';
import { border, fontSize, spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import ProjectImage from '../components/ProjectImage';
import { getTranslation } from '../utils/translations';

const StyledAttributesContainer = styled.div`
	margin-bottom: ${spacer['11']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['13']};
	}
`;

const StyledImagesContainer = styled.div`
	padding-top: ${spacer['5']};
	//border-top: ${border.full};
	
	&:not(:last-child) {
		margin-bottom: ${spacer['7']};
		
		${breakpointUp('md')} {
			margin-bottom: ${spacer['11']};
		}
	}
	
	${breakpointUp('md')} {
		padding-top: ${spacer['6']};
	}
`;

const StyledImagesTitle = styled.h3`
	margin-bottom: ${spacer['6']};
	padding-bottom: ${spacer['3']};
	border-bottom: ${border.full};
	font-size: ${fontSize.base};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['8']};
		padding-bottom: ${spacer['6']};
		font-size: ${fontSize.md};
	}
`;

type ProjectsData = {
	project: ProjectDetail;
};

const ProjectTemplate: React.FC<PageProps<ProjectsData, GatsbyTypes.ProjectQuery>> = ({ path, pageContext, data }) => {
	const attributes = {
		genereal: [
			{
				attribute: getTranslation(data, 'client'),
				value: pageContext.data.project.client,
			},
			{
				attribute: getTranslation(data, 'location'),
				value: pageContext.data.project.location,
			},
			{
				attribute: getTranslation(data, 'year'),
				value: pageContext.data.project.date,
			},
		],
		theatre: [
			{
				attribute: getTranslation(data, 'director'),
				value: pageContext.data.project.director,
			},
			{
				attribute: getTranslation(data, 'scene'),
				value: pageContext.data.project.scene,
			},
		],
		authors: [
			{
				attribute: getTranslation(data, 'productionDesigner'),
				value: pageContext.data.project.productionDesigner,
			},
			{
				attribute: getTranslation(data, 'artDirector'),
				value: pageContext.data.project.artDirector,
			},
		],
	};

	console.log(pageContext.data.project);

	return (
		<Layout
			path={path}
			pageContext={pageContext}
		>
			<PageTitle
				title={pageContext.data.project.title}
				subtitle={replaceCategoryName(pageContext.data.project.category)}
			/>
			<StyledAttributesContainer>
				<Attributes attributes={attributes.genereal} />
				{attributes.theatre.some(({value}) => Boolean(value)) && <Attributes attributes={attributes.theatre} />}
				<Attributes attributes={attributes.authors} />
			</StyledAttributesContainer>
			{pageContext.data.project.imagesGroups.map((group, groupIndex) => (
				<StyledImagesContainer key={group.title}>
					<StyledImagesTitle>{group.title}</StyledImagesTitle>
					{group.images.map((image, imageIndex) => {
						const imgDef = data.projectImages.edges.find(edge => edge.node.relativePath === image.src);
						return imgDef?.node.childImageSharp?.gatsbyImageData && (
							<ProjectImage
								key={image.src}
								image={imgDef?.node.childImageSharp?.gatsbyImageData}
								alt={group.title}
								loading={groupIndex === 0 && imageIndex === 0 ? 'eager' : 'lazy'}
								author={image.author}
							/>
						);
					})}
				</StyledImagesContainer>
			))}
		</Layout>
	);
};

// breakpoints: [293, 586, 1172, 1758, 2344]

export const query = graphql`
	query Project($lang: String!, $projectImages: [String!]) {
		translations: allFile(filter: { name: { eq: $lang }, extension: { eq: "json" } }) {
			nodes {
				childTranslationsJson {
					client
					location
					year
					productionDesigner
					artDirector,
					director,
					scene,
				}
			}
		}
		projectImages: allFile(filter: { relativePath: { in: $projectImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(breakpoints: [293, 586, 1172, 1758], quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;

export default ProjectTemplate;
